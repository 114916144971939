import module from '@ecg-marktplaats/js-backbone-components-lib/src/other/Module';
import Page from '@ecg-marktplaats/js-backbone-components-lib/src/pages/Page';
import LoginForm from 'widgets/LoginForm';

import 'styles/site/pages/LoginAdmarkt/LoginAdmarkt.scss';

export default Page.extend({
  init() {
    // eslint-disable-next-line no-undef
    _.assign(this.configuration, module.config('pages/Login'));

    this.appendChildView(
      'loginForm',
      new LoginForm({
        el: this.el,
        reloadDelay: 0,
        targetQueryParam: 'redirect',
        ssoJsonAuthUrl: this.configuration.ssoJsonAuthUrl,
        tmsid: {
          ...this.configuration.tmsid,
          timestamp: this.configuration.tmsid.timestamp + 1,
        },
        cookieSharedDomain: this.configuration.cookieSharedDomain,
      }),
    );
  },
});
