import dateFnsFormat from 'date-fns/format';

/**
 * Facade for date-fns functions to make them configurable
 */
class DateFns {
  init({ locale }) {
    this.locale = locale;
  }

  format(date, formatString) {
    return dateFnsFormat(date, formatString, { locale: this.locale });
  }
}

export default new DateFns();
