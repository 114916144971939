import i18next from 'i18next';
import Handlebars from 'handlebars';

/**
 * Facade for i18next init with required defaults
 */
const init = ({ lng, translation }) => {
  i18next.init({
    lng,
    resources: {
      [lng]: { translation },
    },
    interpolation: {
      useRawValueToEscape: true,
      escape: Handlebars.Utils.escapeExpression,
    },
    // key potentially can contain untrusted HTML which should be escaped if translation is not found
    parseMissingKeyHandler: (key) => Handlebars.Utils.escapeExpression(key),
  });
};

export default {
  init,
};
