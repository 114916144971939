import $ from 'jquery';
import _ from 'lodash';
import Cookies from 'js-cookie';
import W from '@ecg-marktplaats/js-backbone-components-lib/src/other/Window';
import D from '@ecg-marktplaats/js-backbone-components-lib/src/other/Document';
import View from '@ecg-marktplaats/js-backbone-components-lib/src/widgets/View';

export default View.extend({
  events: {
    'submit #account-login-form': '_submitLoginForm',
  },

  options: {
    checkboxRememberMe: false,
    reloadDelay: 200,
  },

  initialize(options) {
    this.options = _.extend({}, this.constructor.__super__.options, this.options, options);
    this._updatePageRenderedTime();
    this.$submitButton = this.$el.find('#account-login-button');
  },

  _updatePageRenderedTime() {
    const timestamp = new Date().getTime();
    this.$('#account-login-form').attr('data-time-stamp', timestamp);
  },

  _disableSubmitButton() {
    this.$submitButton.prop('disabled', true);
  },

  _enableSubmitButton() {
    // this was made delayed because the next action (redirect) does not always happen synchronously
    // hence one more request could be sometimes sent before the redirect happens
    setTimeout(
      _.bind(function () {
        this.$submitButton.prop('disabled', false);
      }, this),
      1000,
    );
  },

  _submitLoginForm(event) {
    event.preventDefault();

    if (typeof this.options.onSubmit === 'function') {
      this.options.onSubmit();
    }

    const $form = $(event.target);
    const rememberMeControl = $form.find('input[name="remember_me"]');
    const email = $form.find('input[name="j_username"]').val();
    const password = $form.find('input[name="j_password"]').val();
    const rememberMe = this.options.checkboxRememberMe ? rememberMeControl.is(':checked') : rememberMeControl.val();
    const xsrfToken = $form.find('input[name="xsrf.token"]').val();
    const targetUrl = $form.find('input[name="success_url"]').val();

    this._disableSubmitButton();

    this._request(
      {
        email,
        password,
        rememberMe,
        successUrl: targetUrl,
        tmsid: this.options.tmsid,
      },
      xsrfToken,
    )
      .done(
        _.bind(function (data) {
          if (typeof this.options.onSuccess === 'function') {
            this.options.onSuccess();
          }

          Cookies.set('MpLoginSuccess', Date.now(), 60 * 60 * 24 * 30);
          this._reloadDelayed(data.redirectUrl || targetUrl, 0);
        }, this),
      )
      .fail(
        _.bind(function (jqXHR) {
          const params = {
            [this.options.targetQueryParam]: targetUrl,
            error: true,
          };

          if (this.options.checkboxRememberMe) {
            params.rememberMe = rememberMe;
          }

          let response = {};
          try {
            response = JSON.parse(jqXHR.responseText);
          } catch (e) {
            console.log(e);
          }

          let redirectUrl = '';
          switch (jqXHR.status) {
            case 400:
              params[response.errorReason] = true;
              redirectUrl = this._createUrl(params);
              break;
            case 401:
              params.reason = response.errorReason;
              redirectUrl = this._createUrl(params);
              if (response.errorReason === 'CREDENTIALS_MUST_BE_UPDATED') {
                W.ecGa('trackEvent', {
                  eventAction: 'Pwnedpasswordhit',
                  eventLabel: 'Marktplaats',
                });
              }
              break;
            case 403:
              // eslint-disable-next-line prefer-destructuring
              redirectUrl = response.redirectUrl;
              break;
            default:
              redirectUrl = this._createUrl(params);
          }

          if (typeof this.options.onFail === 'function') {
            this.options.onFail();
          }

          this._reloadDelayed(redirectUrl);
        }, this),
      );
  },

  _request(data, xsrfToken) {
    return $.ajax({
      url: this.options.ssoJsonAuthUrl,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(data),
      global: false,
      headers: {
        'x-mp-xsrf': xsrfToken,
      },
      xhrFields: {
        withCredentials: true,
      },
      crossDomain: true,
    });
  },

  _createUrl(params) {
    return `${W.location.pathname}?${$.param(params)}`;
  },

  _isTargetUrlAllowed(href) {
    const location = D.createElement('a');
    location.href = href;

    // IE doesn't populate all link properties when setting .href with a relative URL,
    // however .href will return an absolute URL which then can be used on itself
    // to populate these additional fields.

    if (location.host === '') {
      location.href = location.href;
    }

    const isHttpUrl = _.startsWith(location.protocol, 'http');
    const isRelativeUrl = location.hostname === '' && isHttpUrl;
    const isAbsoluteUrlOnAllowedHost = _.endsWith(location.hostname, this.options.cookieSharedDomain);

    return isRelativeUrl || isAbsoluteUrlOnAllowedHost;
  },

  _reloadDelayed(url) {
    setTimeout(
      _.bind(function () {
        this._enableSubmitButton();

        if (this._isTargetUrlAllowed(url)) {
          W.location = url;
        } else {
          W.location = '/';
        }
      }, this),
      this.options.reloadDelay,
    );
  },
});
